// WheelOfFortune.js

import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { BASE_PROXY_URL } from '../utils/auth';

const prizes = [
  { option: '50 баллов', points: 50, probability: 0.63, style: { backgroundColor: '#ffff00', textColor: '#000000' } },
  { option: '100 баллов', points: 100, probability: 0.15, style: { backgroundColor: '#015099', textColor: '#ffffff' } },
  { option: '150 баллов', points: 150, probability: 0.1, style: { backgroundColor: '#ffff00', textColor: '#000000' } },
  { option: '200 баллов', points: 200, probability: 0.05, style: { backgroundColor: '#bf0543', textColor: '#000000' } },
  { option: '250 баллов', points: 250, probability: 0.04, style: { backgroundColor: '#ff00ff', textColor: '#000000' } },
  { option: '300 баллов', points: 300, probability: 0.03, style: { backgroundColor: '#bf0543', textColor: '#ffffff' } },
];

const selectPrizeIndex = () => {
  const probabilities = prizes.map(prize => prize.probability);
  const cumulative = probabilities.reduce((acc, prob, index) => {
    acc[index] = (acc[index - 1] || 0) + prob;
    return acc;
  }, []);

  const random = Math.random();
  return cumulative.findIndex(threshold => random < threshold);
};

const WheelOfFortune = ({ userId }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [canSpin, setCanSpin] = useState(false);

  // Проверка условий на сервере перед спином
  const checkEligibility = async () => {
    try {
      const response = await fetch('/api/checkEligibility', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
        },
        body: JSON.stringify({ userId })
      });

      if (!response.ok) {
        // Проверка на ошибку 400 и обработка сообщения об ошибке без выброса в консоль
        if (response.status === 400) {
          const result = await response.json();
          setMessage(result.error || 'Условия для спина не выполнены.');
          setCanSpin(false);
        } else {
          // Обработка других ошибок (например, 500) как непредвиденных
          throw new Error('Непредвиденная ошибка');
        }
      } else {
        // Если все в порядке, можно крутить
        setMessage(null);
        setCanSpin(true);
      }
    } catch (error) {
      // Обработка только неожиданных ошибок
      console.error('Произошла ошибка при проверке условий для спина:', error.message || error.statusText);
      setMessage('Произошла ошибка. Пожалуйста, попробуйте позже.');
      setCanSpin(false);
    }
  };

  // Выполняем проверку условий при загрузке компонента
  useEffect(() => {
    checkEligibility();
  }, []);

  const handleSpin = async () => {
    const selectedPrize = prizes[prizeNumber];

    try {
      const response = await fetch(`${BASE_PROXY_URL}/spinResult`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
        },
        body: JSON.stringify({ userId, pointsWon: selectedPrize.points })
      });

      const result = await response.json();
      if (!response.ok) {
        setMessage(result.error || 'Ошибка при выполнении спина.');
      } else {
        setMessage(result.message);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Ошибка при выполнении спина:', error.message);
      setMessage('Произошла ошибка. Пожалуйста, попробуйте позже.');
    }
  };

  return (
    <div className="roulette-container">


      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={prizes}
        onStopSpinning={() => {
          setMustSpin(false);
          handleSpin();
        }}
        spinDuration={2}
      />
      <button onClick={() => {
        if (canSpin) {
          const newPrizeIndex = selectPrizeIndex();
          setPrizeNumber(newPrizeIndex);
          setMustSpin(true);
        }
      }} disabled={!canSpin || mustSpin} className="spin-button">
        Крутить
      </button>
      {message && <p className="message">{message}</p>}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Поздравляем!</h3>
            <p>{message}</p>
            <button onClick={() => setShowModal(false)} className="modal-button">Закрыть</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WheelOfFortune;
